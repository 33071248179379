#modal {
  .page-content {
    padding: 0;
  }
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transform: translateY(100vh);
    transition: transform 10ms 300ms;
    z-index: 1000;
    &.open {
      transform: translateY(0);
      transition: transform 10ms;
      .modal__backdrop {
        background-color: rgba(0, 0, 0, 0.8);
        transition: background-color 300ms 10ms;
      }
      .modal__content {
        transform: translateY(0);
        transition: transform 300ms;
      }
    }
  }
}

#modal {
  .modal {
    &__backdrop {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 300ms;
    }
  }
}

#modal {
  .modal {
    &__content {
      max-width: pxToRem(340);
      width: 100%;
      height: auto;
      color: #000000;
      background-color: #f2f0f0;
      transform: translateY(100vh);
      transition: transform 300ms;
      border-radius: pxToRem(6);
      overflow: visible;

      @include mq(sm) {
        max-width: pxToRem(300);
      }
    }
  }
}

#modal {
  .modal {
    &__close {
      display: flex;
      justify-content: center;
      align-items: center;
      width: pxToRem(32);
      height: pxToRem(32);
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: pxToRem(8) pxToRem(11) pxToRem(11) pxToRem(13);
      @include fontSizePxToRem(21);
      line-height: 1;
      background-color: #E8E5E5;
      border-radius: 50%;
      cursor: pointer;
      transform: translate(pxToRem(8), pxToRem(-9));

      @include  mq() {
        transform: translate(pxToRem(10), pxToRem(-11));
      }
    }
  }
}

#modal {
  .content {

    &__wrapper {
      width: 100%;
      padding: pxToRem(30) pxToRem(27) pxToRem(30) pxToRem(27);

      @include mq(sm) {
        padding: pxToRem(16) pxToRem(14) pxToRem(16) pxToRem(14);
      }
    }

    &__inner {
      width: 100%;
      height: auto;
    }

    &__soldout {
    }

  }
}

#modal {
  .soldout {
    &__title {
      margin: 0;
      margin-bottom: pxToRem(8);
      @include fontSizePxToRem(26.5);
      @extend .font__futura-bold;
      text-align: center;
      line-height: 1;
      text-transform: uppercase;
    }

    &__text {
      margin: 0;
      margin-bottom: pxToRem(20);
      @include fontSizePxToRem(18);
      @extend .font__midashi-go-mb31-pr6n;
      text-align: center;
      line-height: 1;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      margin-bottom: pxToRem(18);
      @include fontSizePxToRem(14);
      @extend .font__midashi-go-mb31-pr6n;
      line-height: 1;

      span {
        &:first-child {
          margin-bottom: pxToRem(5);
        }
      }
    }

    &__image {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: pxToRem(14);
    }

    &__link {
      display: block;
      margin-bottom: pxToRem(14);
      padding: pxToRem(10) 0;
      line-height: 1;
      background-color: #51a3d1;
      color: #FFF;
      @include fontSizePxToRem(12);
      @extend .font__midashi-go-mb31-pr6n;
      text-align: center;
      border-radius: pxToRem(2);
    }

    &__close--wrapper {
      margin: 0;
      line-height: 1;
      text-align: center;
    }

    &__close {
      display: inline;
      margin: 0;
      color: #3e3a39;
      @include fontSizePxToRem(12);
      line-height: 1;
    }
  }
}

#modal {
  @include mq() {
    .sqs-layout .sqs-row .sqs-block:first-child {
      padding-top: 0 !important;
    }
    .sqs-layout .sqs-row .sqs-block:last-child {
      padding-bottom: 0 !important;
    }
    .sqs-layout .sqs-row .sqs-block {
      padding: 0;
    }
  }
}
