#top {

  .page-content {
    width: 100vw;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-block {
    padding: 0;

    @include mq() {
      padding: 0 !important;
    }
  }

  .container {
    position: relative;
    width: 100vw;
    height: auto;
    line-height: 1;

    h1, h2, p {
      margin: 0;
    }

    h1 {
      line-height: 1;
    }

    &__image {
      display: block;
      width: 100%;
      height: 100%;
      z-index: 3;
      font-size: 0px;
      text-align: center;

      img {
        width: 100%;
      }
    }

    &__left {
      position: absolute;
      left: 0;
      bottom: 3.5vw;
      z-index: 5;

      @include mq() {
        bottom: 24vw;
      }
    }

    &__right {
      width: 27vw;
      position: absolute;
      right: 2vw;
      bottom: 3.5vw;
      z-index: 5;

      @include mq() {
        width: 50vw;
        right: 3vw;
        bottom: 4vw;
      }
    }

    &__memorialset {
      display: inline-block;
    }

  }

  .memorialset {
    display: flex;
    flex-direction: column;
    margin-bottom: pxToRem(26);

    @include mq() {
      margin-bottom: pxToRem(15);
    }

    &__text {
      @extend .font__midashi-go-mb31-pr6n;
      line-height: 1;

      &--first {
        margin-bottom: pxToRem(4) !important;
        padding: pxToRem(2);
        background-color: #fff;
        @include fontSizePxToRem(24);
        color: #ddbc6b;
        line-height: 1;

        @include mq() {
          margin-bottom: pxToRem(2.5) !important;
          @include fontSizePxToRem(20);
        }
      }

      &--second {
        background-color: #FFF;
        padding: pxToRem(2);
        padding-bottom: pxToRem(4);
        @include fontSizePxToRem(21.8);
        color: #ddbc6b;
        line-height: 1;

        @include mq() {
          @include fontSizePxToRem(18.3);
        }
      }

      &--small {
        @include fontSizePxToRem(11);

        @include mq() {
          @include fontSizePxToRem(9);
        }
      }
    }
  }

  .limitednumber {
    &__amount {
      display: inline-block;
      background-color: #ddbc6b;
    margin-bottom: pxToRem(4) !important;
      padding: pxToRem(5);
      padding-right: pxToRem(10);
      @include fontSizePxToRem(25.3);
      @extend .font__midashi-go-mb31-pr6n;
      line-height: 1;

      @include mq() {
        margin-bottom: pxToRem(2.5) !important;
        padding: pxToRem(5) pxToRem(7.5) pxToRem(2.5) pxToRem(5);
        @include fontSizePxToRem(21);
      }
    }

    &__text {
      display: inline-block;
      background-color: #ddbc6b;
      margin-bottom: pxToRem(4) !important;
      padding: pxToRem(5);
      padding-right: pxToRem(10);
      @include fontSizePxToRem(25.3);
      @extend .font__midashi-go-mb31-pr6n;
      line-height: 1;

      @include mq() {
        margin-bottom: pxToRem(2.5) !important;
        padding: pxToRem(5) pxToRem(7.5) pxToRem(2.5) pxToRem(5);
        @include fontSizePxToRem(21);
      }
    }

    &__date {
      display: inline-block;
      background-color: #ddbc6b;
      padding: pxToRem(5);
      padding-right: pxToRem(10);
      @include fontSizePxToRem(14.5);
      @extend .font__midashi-go-mb31-pr6n;
      line-height: 1;

      @include mq() {
        padding: pxToRem(4) pxToRem(5) pxToRem(3) pxToRem(5);
        @include fontSizePxToRem(12);
      }
    }

    &--futura-bold {
      @extend .font__futura-bold;
      line-height: 1;
    }

  }

}
