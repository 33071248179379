#point2 {

  @include mq() {

    .page-content {
      max-width: 100vw;
      padding-right: 0;
      padding-left: 0;
      margin: 0;
    }

    .sqs-layout > .sqs-row {
      margin: 0;
    }

    .sqs-block {
      padding: 0;
    }

  }

}
