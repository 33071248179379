#limited-top {

  .page-content {
    width: 100vw;
    max-width: 100%;
    margin: 0;
    padding: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-block {
    padding: 0;

    @include mq() {
      padding: 0 !important;
    }
  }

  .container {
    position: relative;
    width: 100vw;
    height: auto;
    line-height: 1;

    h1, h2, p {
      margin: 0;
    }

    h1 {
      line-height: 1;
    }

    &__image {
      display: block;
      width: 100%;
      height: 100%;
      z-index: 3;
      font-size: 0px;
      text-align: center;

      img {
        width: 100%;
      }
    }

    &__left {
      position: absolute;
      left: 0;
      bottom: 4vw;
      z-index: 5;

      @include mq() {
        bottom: 31vw;
      }
    }

    &__right {
      width: 27vw;
      position: absolute;
      right: 2vw;
      bottom: 2.5vw;
      z-index: 5;

      @include mq() {
        width: 50vw;
        right: 4vw;
        bottom: 5vw;
      }
    }

    &__memorialset {
      display: inline-block;
    }

  }

  .limitednumber {
    &__amount {
      display: inline-block;
      background-color: #ddbc6b;
    margin-bottom: pxToRem(7) !important;
      padding: pxToRem(5) pxToRem(6.5) pxToRem(2.5) pxToRem(2.5);
      @include fontSizePxToRem(19);
      @extend .font__midashi-go-mb31-pr6n;
      line-height: 1;

    }

    &__text {
      display: inline-block;
      background-color: #ddbc6b;
      margin-bottom: pxToRem(2.5) !important;
      padding: pxToRem(2.5);
      padding-right: pxToRem(8);
      @include fontSizePxToRem(20);
      @extend .font__midashi-go-mb31-pr6n;
      line-height: 1;

    }

    &__date {
      display: inline-block;
      background-color: #ddbc6b;
      padding: pxToRem(2);
      padding-right: pxToRem(9);
      @include fontSizePxToRem(20);
      @extend .font__midashi-go-mb31-pr6n;
      line-height: 1;

    }

  }

}
