#limited-point {

  .page-content {
    max-width: 100vw;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    margin: 0;
  }

  .sqs-layout > .sqs-row {
    margin: 0;
  }

  .sqs-block-code {
    padding: 0;

    @include mq() {
      padding: 0 !important;
    }
  }

}
