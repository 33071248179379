* {
  box-sizing: border-box;
  font-feature-settings: "palt";
}

body {
  padding-bottom: pxToRem(69);
  font-family: Midashi Go MB31, sans-serif;
  font-style: normal;
  font-weight: 600;
}

#footer {
  @include mq() {
    .nav-wrapper {
      display: none;
    }
  }
}

#footer .sqs-gallery-design-grid-slide {
  @include mq() {
    width: 33.3333% !important;
  }
}
