#limited-modal {
  .page-content {
    padding: 0;
  }
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    transform: translateY(100vh);
    transition: transform 10ms 300ms;
    z-index: 1000;
    &.open {
      transform: translateY(0);
      transition: transform 10ms;
      .modal__backdrop {
        background-color: rgba(0, 0, 0, 0.8);
        transition: background-color 300ms 10ms;
      }
      .modal__content {
        transform: translateY(0);
        transition: transform 300ms;
      }
    }
  }
}

#limited-modal {
  .modal {
    &__backdrop {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 300ms;
    }
  }
}

#limited-modal {
  .modal {
    &__content {
      max-width: pxToRem(340);
      width: 100%;
      height: auto;
      color: #000000;
      background-color: #f2f0f0;
      transform: translateY(100vh);
      transition: transform 300ms;
      border-radius: pxToRem(6);
      overflow: visible;

      @include mq(sm) {
        max-width: pxToRem(300);
      }
    }
  }
}

#limited-modal {
  .modal {
    &__close {
      display: flex;
      justify-content: center;
      align-items: center;
      width: pxToRem(32);
      height: pxToRem(32);
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: pxToRem(8) pxToRem(11) pxToRem(11) pxToRem(13);
      @include fontSizePxToRem(21);
      line-height: 1;
      background-color: #E8E5E5;
      border-radius: 50%;
      cursor: pointer;
      transform: translate(pxToRem(8), pxToRem(-9));

      @include  mq() {
        transform: translate(pxToRem(10), pxToRem(-11));
      }
    }
  }
}

#limited-modal {
  .content {

    &__wrapper {
      width: 100%;
      padding: pxToRem(30) pxToRem(27) pxToRem(0) pxToRem(27);

      @include mq(sm) {
        padding: pxToRem(16) pxToRem(14) pxToRem(0) pxToRem(14);
      }
    }

    &__soldout {
      margin-bottom: pxToRem(15);
    }

    &__soldout-text {
      margin: 0;
      text-align: center;
      @extend .font__gothic-bbb-pr6n;
      @include fontSizePxToRem(12);
      line-height: 1;

      &--1 {
        margin-bottom: pxToRem(5);
      }
    }

    &__inner {
      width: 100%;
      height: auto;
      overflow-y: scroll;
    }

    &__list {
    }

  }
}

#limited-modal {
  .content {
    .setC {
      margin-bottom: pxToRem(20);
      padding-bottom: pxToRem(20);
      text-align: center;
      border-bottom: 0.5px solid #707070;

      &__image {
        display: flex;
        flex-direction: row;
        img {
          display: block;
          width: 23%;
          height: pxToRem(45);
        }
      }

      &__text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        @extend .font__gothic-bbb-pr6n;
        @include fontSizePxToRem(14);
        line-height: 1;

        &--title {
          margin: pxToRem(15.5) 0;
          @extend .font__midashi-go-mb31-pr6n;
        }

        &--price {
          margin: pxToRem(15.5) 0;
        }

        &--tax {
          @include fontSizePxToRem(10);
        }
      }

      &__purchaseButton {
        button {
          width: 100%;
          padding: pxToRem(10) 0;
          border: none;
          background-color: #51a3d1;
          border-radius: pxToRem(2);
        }

        .reserve {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          &__image {
            width: pxToRem(14);
            height: pxToRem(14);
            margin-right: 20px;
          }

          &__text {
            width: 60%;
            margin: 0;
            @extend .font__midashi-go-mb31-pr6n;
            @include fontSizePxToRem(12);
            line-height: 1;
            color: #fff;
            text-align: left;
            transform: translateX(pxToRem(5));
          }
        }
      }
    }

  }
}

#limited-modal {
  .content {
    .setC-option {
      text-align: center;
      padding-bottom: pxToRem(20);
      border-bottom: 0.5px solid #707070;

      &__image {
        position: relative;
        display: flex;
        flex-direction: row;
        img {
          display: block;
          width: 23%;
          height: pxToRem(45);
        }

        &--04 {
          margin-left: pxToRem(26);
        }

        &::after {
          content: "+";
          position: absolute;
          left: 0;
          @include fontSizePxToRem(20);
          transform: translate(72px , 11px);

          @include mq(sm) {
            transform: translate(69px , 11px);
          }

        }
      }

      &__text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto;
        @extend .font__gothic-bbb-pr6n;
        @include fontSizePxToRem(14);
        line-height: 1;

        &--title {
          margin: pxToRem(15.5) 0;
          @extend .font__midashi-go-mb31-pr6n;
        }

        &--price {
          margin: pxToRem(15.5) 0;
        }

        &--tax {
          @include fontSizePxToRem(10);
        }
      }

      &__purchaseButton {
        button {
          width: 100%;
          padding: pxToRem(10) 0;
          border: none;
          background-color: #51a3d1;
          border-radius: pxToRem(2);
        }

        .reserve {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          &__image {
            width: pxToRem(14);
            height: pxToRem(14);
            margin-right: 20px;
          }

          &__text {
            width: 60%;
            margin: 0;
            @extend .font__midashi-go-mb31-pr6n;
            @include fontSizePxToRem(12);
            line-height: 1;
            color: #fff;
            text-align: left;
            transform: translateX(pxToRem(5));
          }
        }
      }
    }

  }
}

#limited-modal {
  .content {
    .text {
      margin: 0 auto;
      margin-top: pxToRem(20);
      @extend .font__gothic-bbb-pr6n;
      @include fontSizePxToRem(12);
      color: #3e3a39;
      text-align: left;
      padding: pxToRem(0) pxToRem(27) pxToRem(40) pxToRem(27);

      @include mq(sm) {
        padding: pxToRem(0) pxToRem(14) pxToRem(20) pxToRem(14);
      }

      &__date {
        margin: 0;
        margin-top: pxToRem(7);
        line-height: 1;
      }

      &__free {
        margin: 0;
        margin-top: pxToRem(5);
        line-height: 1;
      }
    }
  }
}

#limited-modal {
  @include mq() {
    .sqs-layout .sqs-row .sqs-block:first-child {
      padding-top: 0 !important;
    }
    .sqs-layout .sqs-row .sqs-block:last-child {
      padding-bottom: 0 !important;
    }
    .sqs-layout .sqs-row .sqs-block {
      padding: 0;
    }
  }
}
