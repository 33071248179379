.font {
  &__midashi-go-mb31-pr6n {
    font-family: Midashi Go MB31, sans-serif;
    font-style: normal;
    font-weight: 600;
  }

  &__gothic-bbb-pr6n {
    font-family: Gothic Medium BBB, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  &__futura-bold {
    font-family: futura-pt-bold, sans-serif;
    font-style: normal;
    font-weight: 700;
  }

}
