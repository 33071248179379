#header {
  display: none;
}

body {
  font-family: YakuHanJP, Gothic Medium BBB, sans-serif;
}

h1, h2, h3 {
  font-family: YakuHanJP, Midashi Go MB31, sans-serif;
}

h3 {
  text-transform: inherit;
}

blockquote {
  padding: 0;
  font-size: 0.875em;
  font-style: normal;
}

button {
  opacity: 1;
  transition: opacity 500ms;
}

button:hover, button:active, button:focus {
  opacity: 0.8;
}

@media screen and (max-width: 640px) {
  .Footer .sqs-gallery-design-grid-slide {
    width: 33.3333% !important;
  }
}

.font__midashi-go-mb31-pr6n, #floatingfooter .container .memorialset__amount, #floatingfooter .container .memorialset__text, #floatingfooter .container .button__text--reserve, #floatingfooter .container .button__text--date, #top .memorialset__text, #top .limitednumber__amount, #top .limitednumber__text, #top .limitednumber__date, #modal .soldout__text, #modal .soldout__info, #modal .soldout__link, #limited-floatingfooter .container .memorialset__amount, #limited-floatingfooter .container .memorialset__limited, #limited-floatingfooter .container .memorialset__text, #limited-floatingfooter .container .button__text--reserve, #limited-floatingfooter .container .button__text--date, #limited-top .limitednumber__amount, #limited-top .limitednumber__text, #limited-top .limitednumber__date, #limited-modal .content .setC__text--title, #limited-modal .content .setC__purchaseButton .reserve__text, #limited-modal .content .setC-option__text--title, #limited-modal .content .setC-option__purchaseButton .reserve__text {
  font-family: Midashi Go MB31, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.font__gothic-bbb-pr6n, #limited-modal .content__soldout-text, #limited-modal .content .setC__text, #limited-modal .content .setC-option__text, #limited-modal .content .text {
  font-family: Gothic Medium BBB, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.font__futura-bold, #top .limitednumber--futura-bold, #modal .soldout__title {
  font-family: futura-pt-bold, sans-serif;
  font-style: normal;
  font-weight: 700;
}

* {
  box-sizing: border-box;
  font-feature-settings: "palt";
}

body {
  padding-bottom: 4.3125rem;
  font-family: Midashi Go MB31, sans-serif;
  font-style: normal;
  font-weight: 600;
}

@media screen and (max-width: 735px) {
  #footer .nav-wrapper {
    display: none;
  }
}

@media screen and (max-width: 735px) {
  #footer .sqs-gallery-design-grid-slide {
    width: 33.3333% !important;
  }
}

#point1 .page-content {
  max-width: 100vw;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin: 0;
}

#point1 .sqs-layout > .sqs-row {
  margin: 0;
}

#point1 .sqs-block {
  padding: 0;
}

@media screen and (max-width: 735px) {
  #point1 .sqs-block {
    padding: 0 !important;
  }
}

@media screen and (max-width: 735px) {
  #point2 .page-content {
    max-width: 100vw;
    padding-right: 0;
    padding-left: 0;
    margin: 0;
  }
  #point2 .sqs-layout > .sqs-row {
    margin: 0;
  }
  #point2 .sqs-block {
    padding: 0;
  }
}

#floatingfooter.show {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}

#floatingfooter {
  width: 100vw;
  height: 4.3125rem;
  height: constant(69pxenv(safe-area-inset-bottom));
  height: calc(69px + env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #3073ac;
  opacity: 0;
  z-index: 999;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

#floatingfooter * {
  line-height: 1;
}

#floatingfooter .page-content {
  padding: 0;
}

#floatingfooter .container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 23.4375rem;
  margin: 0 auto;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container {
    max-width: 20rem;
  }
}

#floatingfooter .container .memorialset {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #ffffff;
}

#floatingfooter .container .memorialset__amount {
  margin: 0;
  font-size: 14px;
  font-size: 0.875rem;
  letter-spacing: 0.0125rem;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .memorialset__amount {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

#floatingfooter .container .memorialset__text {
  margin: 0;
  margin-top: 0.40625rem;
  margin-bottom: 0.3125rem;
  font-size: 19px;
  font-size: 1.1875rem;
}

@media screen and (max-width: 735px) {
  #floatingfooter .container .memorialset__text {
    margin-top: 0.4375rem;
    margin-bottom: 0.125rem;
  }
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .memorialset__text {
    font-size: 16px;
    font-size: 1rem;
  }
}

#floatingfooter .container .memorialset__text--small {
  margin-left: 0.3125rem;
  font-size: 10px;
  font-size: 0.625rem;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .memorialset__text--small {
    font-size: 8px;
    font-size: 0.5rem;
  }
}

#floatingfooter .container .button {
  display: flex;
  align-items: center;
  padding: 0.46875rem 1.09375rem 0.4375rem 1.125rem;
  background-color: #e5c932;
  border-radius: 0.1875rem;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 735px) {
  #floatingfooter .container .button {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .button {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

#floatingfooter .container .button__icon {
  width: 1.375rem;
  height: 1.375rem;
}

#floatingfooter .container .button__text {
  margin-left: 0.5rem;
  color: #3073ac;
}

#floatingfooter .container .button__text--reserve {
  margin: 0;
  font-size: 19px;
  font-size: 1.1875rem;
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .button__text--reserve {
    font-size: 16px;
    font-size: 1rem;
  }
}

#floatingfooter .container .button__text--date {
  margin: 0;
  margin-top: 0.0625rem;
  font-size: 9px;
  font-size: 0.5625rem;
  text-align: center;
  letter-spacing: 0.10125rem;
}

@media screen and (max-width: 735px) {
  #floatingfooter .container .button__text--date {
    letter-spacing: 0;
  }
}

@media screen and (max-width: 374px) {
  #floatingfooter .container .button__text--date {
    font-size: 7px;
    font-size: 0.4375rem;
  }
}

#aset .purchase__button, #bset .purchase__button {
  padding: 0;
  background: transparent;
  border: none;
}

#top .page-content {
  width: 100vw;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

#top .sqs-layout > .sqs-row {
  margin: 0;
}

#top .sqs-block {
  padding: 0;
}

@media screen and (max-width: 735px) {
  #top .sqs-block {
    padding: 0 !important;
  }
}

#top .container {
  position: relative;
  width: 100vw;
  height: auto;
  line-height: 1;
}

#top .container h1, #top .container h2, #top .container p {
  margin: 0;
}

#top .container h1 {
  line-height: 1;
}

#top .container__image {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 3;
  font-size: 0px;
  text-align: center;
}

#top .container__image img {
  width: 100%;
}

#top .container__left {
  position: absolute;
  left: 0;
  bottom: 3.5vw;
  z-index: 5;
}

@media screen and (max-width: 735px) {
  #top .container__left {
    bottom: 24vw;
  }
}

#top .container__right {
  width: 27vw;
  position: absolute;
  right: 2vw;
  bottom: 3.5vw;
  z-index: 5;
}

@media screen and (max-width: 735px) {
  #top .container__right {
    width: 50vw;
    right: 3vw;
    bottom: 4vw;
  }
}

#top .container__memorialset {
  display: inline-block;
}

#top .memorialset {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.625rem;
}

@media screen and (max-width: 735px) {
  #top .memorialset {
    margin-bottom: 0.9375rem;
  }
}

#top .memorialset__text {
  line-height: 1;
}

#top .memorialset__text--first {
  margin-bottom: 0.25rem !important;
  padding: 0.125rem;
  background-color: #fff;
  font-size: 24px;
  font-size: 1.5rem;
  color: #ddbc6b;
  line-height: 1;
}

@media screen and (max-width: 735px) {
  #top .memorialset__text--first {
    margin-bottom: 0.15625rem !important;
    font-size: 20px;
    font-size: 1.25rem;
  }
}

#top .memorialset__text--second {
  background-color: #FFF;
  padding: 0.125rem;
  padding-bottom: 0.25rem;
  font-size: 21.8px;
  font-size: 1.3625rem;
  color: #ddbc6b;
  line-height: 1;
}

@media screen and (max-width: 735px) {
  #top .memorialset__text--second {
    font-size: 18.3px;
    font-size: 1.14375rem;
  }
}

#top .memorialset__text--small {
  font-size: 11px;
  font-size: 0.6875rem;
}

@media screen and (max-width: 735px) {
  #top .memorialset__text--small {
    font-size: 9px;
    font-size: 0.5625rem;
  }
}

#top .limitednumber__amount {
  display: inline-block;
  background-color: #ddbc6b;
  margin-bottom: 0.25rem !important;
  padding: 0.3125rem;
  padding-right: 0.625rem;
  font-size: 25.3px;
  font-size: 1.58125rem;
  line-height: 1;
}

@media screen and (max-width: 735px) {
  #top .limitednumber__amount {
    margin-bottom: 0.15625rem !important;
    padding: 0.3125rem 0.46875rem 0.15625rem 0.3125rem;
    font-size: 21px;
    font-size: 1.3125rem;
  }
}

#top .limitednumber__text {
  display: inline-block;
  background-color: #ddbc6b;
  margin-bottom: 0.25rem !important;
  padding: 0.3125rem;
  padding-right: 0.625rem;
  font-size: 25.3px;
  font-size: 1.58125rem;
  line-height: 1;
}

@media screen and (max-width: 735px) {
  #top .limitednumber__text {
    margin-bottom: 0.15625rem !important;
    padding: 0.3125rem 0.46875rem 0.15625rem 0.3125rem;
    font-size: 21px;
    font-size: 1.3125rem;
  }
}

#top .limitednumber__date {
  display: inline-block;
  background-color: #ddbc6b;
  padding: 0.3125rem;
  padding-right: 0.625rem;
  font-size: 14.5px;
  font-size: 0.90625rem;
  line-height: 1;
}

@media screen and (max-width: 735px) {
  #top .limitednumber__date {
    padding: 0.25rem 0.3125rem 0.1875rem 0.3125rem;
    font-size: 12px;
    font-size: 0.75rem;
  }
}

#top .limitednumber--futura-bold {
  line-height: 1;
}

#modal .page-content {
  padding: 0;
}

#modal .modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: translateY(100vh);
  transition: transform 10ms 300ms;
  z-index: 1000;
}

#modal .modal.open {
  transform: translateY(0);
  transition: transform 10ms;
}

#modal .modal.open .modal__backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  transition: background-color 300ms 10ms;
}

#modal .modal.open .modal__content {
  transform: translateY(0);
  transition: transform 300ms;
}

#modal .modal__backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 300ms;
}

#modal .modal__content {
  max-width: 21.25rem;
  width: 100%;
  height: auto;
  color: #000000;
  background-color: #f2f0f0;
  transform: translateY(100vh);
  transition: transform 300ms;
  border-radius: 0.375rem;
  overflow: visible;
}

@media screen and (max-width: 374px) {
  #modal .modal__content {
    max-width: 18.75rem;
  }
}

#modal .modal__close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0.5rem 0.6875rem 0.6875rem 0.8125rem;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1;
  background-color: #E8E5E5;
  border-radius: 50%;
  cursor: pointer;
  transform: translate(0.5rem, -0.5625rem);
}

@media screen and (max-width: 735px) {
  #modal .modal__close {
    transform: translate(0.625rem, -0.6875rem);
  }
}

#modal .content__wrapper {
  width: 100%;
  padding: 1.875rem 1.6875rem 1.875rem 1.6875rem;
}

@media screen and (max-width: 374px) {
  #modal .content__wrapper {
    padding: 1rem 0.875rem 1rem 0.875rem;
  }
}

#modal .content__inner {
  width: 100%;
  height: auto;
}

#modal .soldout__title {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 26.5px;
  font-size: 1.65625rem;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
}

#modal .soldout__text {
  margin: 0;
  margin-bottom: 1.25rem;
  font-size: 18px;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1;
}

#modal .soldout__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-bottom: 1.125rem;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1;
}

#modal .soldout__info span:first-child {
  margin-bottom: 0.3125rem;
}

#modal .soldout__image {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 0.875rem;
}

#modal .soldout__link {
  display: block;
  margin-bottom: 0.875rem;
  padding: 0.625rem 0;
  line-height: 1;
  background-color: #51a3d1;
  color: #FFF;
  font-size: 12px;
  font-size: 0.75rem;
  text-align: center;
  border-radius: 0.125rem;
}

#modal .soldout__close--wrapper {
  margin: 0;
  line-height: 1;
  text-align: center;
}

#modal .soldout__close {
  display: inline;
  margin: 0;
  color: #3e3a39;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1;
}

@media screen and (max-width: 735px) {
  #modal .sqs-layout .sqs-row .sqs-block:first-child {
    padding-top: 0 !important;
  }
  #modal .sqs-layout .sqs-row .sqs-block:last-child {
    padding-bottom: 0 !important;
  }
  #modal .sqs-layout .sqs-row .sqs-block {
    padding: 0;
  }
}

#limited-point .page-content {
  max-width: 100vw;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin: 0;
}

#limited-point .sqs-layout > .sqs-row {
  margin: 0;
}

#limited-point .sqs-block-code {
  padding: 0;
}

@media screen and (max-width: 735px) {
  #limited-point .sqs-block-code {
    padding: 0 !important;
  }
}

#limited-floatingfooter.show {
  opacity: 1;
  transition: opacity 1000ms 600ms;
}

#limited-floatingfooter {
  width: 100vw;
  height: 4.3125rem;
  height: constant(69pxenv(safe-area-inset-bottom));
  height: calc(69px + env(safe-area-inset-bottom));
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #3073ac;
  opacity: 0;
  z-index: 999;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

#limited-floatingfooter * {
  line-height: 1;
}

#limited-floatingfooter .page-content {
  padding: 0;
}

#limited-floatingfooter .container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 23.4375rem;
  margin: 0 auto;
}

@media screen and (max-width: 374px) {
  #limited-floatingfooter .container {
    max-width: 20rem;
  }
}

#limited-floatingfooter .container .memorialset {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #ffffff;
}

#limited-floatingfooter .container .memorialset__amount {
  margin: 0;
  margin-top: 0.125rem;
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 0.0125rem;
}

#limited-floatingfooter .container .memorialset__limited {
  margin: 0;
  margin-top: 0.1875rem;
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 0.0125rem;
}

#limited-floatingfooter .container .memorialset__text {
  margin: 0;
  margin-top: 0.1875rem;
  margin-bottom: 0.3125rem;
  font-size: 19px;
  font-size: 1.1875rem;
}

@media screen and (max-width: 735px) {
  #limited-floatingfooter .container .memorialset__text {
    margin-bottom: 0.125rem;
  }
}

@media screen and (max-width: 374px) {
  #limited-floatingfooter .container .memorialset__text {
    font-size: 16px;
    font-size: 1rem;
  }
}

#limited-floatingfooter .container .memorialset__text--small {
  margin-left: 0.3125rem;
  font-size: 10px;
  font-size: 0.625rem;
}

@media screen and (max-width: 374px) {
  #limited-floatingfooter .container .memorialset__text--small {
    font-size: 8px;
    font-size: 0.5rem;
  }
}

#limited-floatingfooter .container .button {
  display: flex;
  align-items: center;
  padding: 0.46875rem 1.09375rem 0.4375rem 1.125rem;
  background-color: #e5c932;
  border-radius: 0.1875rem;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 735px) {
  #limited-floatingfooter .container .button {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media screen and (max-width: 374px) {
  #limited-floatingfooter .container .button {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}

#limited-floatingfooter .container .button__icon {
  width: 1.375rem;
  height: 1.375rem;
}

#limited-floatingfooter .container .button__text {
  margin-left: 0.5rem;
  color: #3073ac;
}

#limited-floatingfooter .container .button__text--reserve {
  margin: 0;
  font-size: 19px;
  font-size: 1.1875rem;
}

@media screen and (max-width: 374px) {
  #limited-floatingfooter .container .button__text--reserve {
    font-size: 16px;
    font-size: 1rem;
  }
}

#limited-floatingfooter .container .button__text--date {
  margin: 0;
  margin-top: 0.0625rem;
  font-size: 9px;
  font-size: 0.5625rem;
  text-align: center;
  letter-spacing: 0.10125rem;
}

@media screen and (max-width: 735px) {
  #limited-floatingfooter .container .button__text--date {
    letter-spacing: 0;
  }
}

@media screen and (max-width: 374px) {
  #limited-floatingfooter .container .button__text--date {
    font-size: 7px;
    font-size: 0.4375rem;
  }
}

#limitedset .purchase__button {
  padding: 0;
  background: transparent;
  border: none;
}

#limited-top .page-content {
  width: 100vw;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

#limited-top .sqs-layout > .sqs-row {
  margin: 0;
}

#limited-top .sqs-block {
  padding: 0;
}

@media screen and (max-width: 735px) {
  #limited-top .sqs-block {
    padding: 0 !important;
  }
}

#limited-top .container {
  position: relative;
  width: 100vw;
  height: auto;
  line-height: 1;
}

#limited-top .container h1, #limited-top .container h2, #limited-top .container p {
  margin: 0;
}

#limited-top .container h1 {
  line-height: 1;
}

#limited-top .container__image {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 3;
  font-size: 0px;
  text-align: center;
}

#limited-top .container__image img {
  width: 100%;
}

#limited-top .container__left {
  position: absolute;
  left: 0;
  bottom: 4vw;
  z-index: 5;
}

@media screen and (max-width: 735px) {
  #limited-top .container__left {
    bottom: 31vw;
  }
}

#limited-top .container__right {
  width: 27vw;
  position: absolute;
  right: 2vw;
  bottom: 2.5vw;
  z-index: 5;
}

@media screen and (max-width: 735px) {
  #limited-top .container__right {
    width: 50vw;
    right: 4vw;
    bottom: 5vw;
  }
}

#limited-top .container__memorialset {
  display: inline-block;
}

#limited-top .limitednumber__amount {
  display: inline-block;
  background-color: #ddbc6b;
  margin-bottom: 0.4375rem !important;
  padding: 0.3125rem 0.40625rem 0.15625rem 0.15625rem;
  font-size: 19px;
  font-size: 1.1875rem;
  line-height: 1;
}

#limited-top .limitednumber__text {
  display: inline-block;
  background-color: #ddbc6b;
  margin-bottom: 0.15625rem !important;
  padding: 0.15625rem;
  padding-right: 0.5rem;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1;
}

#limited-top .limitednumber__date {
  display: inline-block;
  background-color: #ddbc6b;
  padding: 0.125rem;
  padding-right: 0.5625rem;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1;
}

#limited-modal .page-content {
  padding: 0;
}

#limited-modal .modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: translateY(100vh);
  transition: transform 10ms 300ms;
  z-index: 1000;
}

#limited-modal .modal.open {
  transform: translateY(0);
  transition: transform 10ms;
}

#limited-modal .modal.open .modal__backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  transition: background-color 300ms 10ms;
}

#limited-modal .modal.open .modal__content {
  transform: translateY(0);
  transition: transform 300ms;
}

#limited-modal .modal__backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 300ms;
}

#limited-modal .modal__content {
  max-width: 21.25rem;
  width: 100%;
  height: auto;
  color: #000000;
  background-color: #f2f0f0;
  transform: translateY(100vh);
  transition: transform 300ms;
  border-radius: 0.375rem;
  overflow: visible;
}

@media screen and (max-width: 374px) {
  #limited-modal .modal__content {
    max-width: 18.75rem;
  }
}

#limited-modal .modal__close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0.5rem 0.6875rem 0.6875rem 0.8125rem;
  font-size: 21px;
  font-size: 1.3125rem;
  line-height: 1;
  background-color: #E8E5E5;
  border-radius: 50%;
  cursor: pointer;
  transform: translate(0.5rem, -0.5625rem);
}

@media screen and (max-width: 735px) {
  #limited-modal .modal__close {
    transform: translate(0.625rem, -0.6875rem);
  }
}

#limited-modal .content__wrapper {
  width: 100%;
  padding: 1.875rem 1.6875rem 0rem 1.6875rem;
}

@media screen and (max-width: 374px) {
  #limited-modal .content__wrapper {
    padding: 1rem 0.875rem 0rem 0.875rem;
  }
}

#limited-modal .content__soldout {
  margin-bottom: 0.9375rem;
}

#limited-modal .content__soldout-text {
  margin: 0;
  text-align: center;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1;
}

#limited-modal .content__soldout-text--1 {
  margin-bottom: 0.3125rem;
}

#limited-modal .content__inner {
  width: 100%;
  height: auto;
  overflow-y: scroll;
}

#limited-modal .content .setC {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  text-align: center;
  border-bottom: 0.5px solid #707070;
}

#limited-modal .content .setC__image {
  display: flex;
  flex-direction: row;
}

#limited-modal .content .setC__image img {
  display: block;
  width: 23%;
  height: 2.8125rem;
}

#limited-modal .content .setC__text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1;
}

#limited-modal .content .setC__text--title {
  margin: 0.96875rem 0;
}

#limited-modal .content .setC__text--price {
  margin: 0.96875rem 0;
}

#limited-modal .content .setC__text--tax {
  font-size: 10px;
  font-size: 0.625rem;
}

#limited-modal .content .setC__purchaseButton button {
  width: 100%;
  padding: 0.625rem 0;
  border: none;
  background-color: #51a3d1;
  border-radius: 0.125rem;
}

#limited-modal .content .setC__purchaseButton .reserve {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#limited-modal .content .setC__purchaseButton .reserve__image {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 20px;
}

#limited-modal .content .setC__purchaseButton .reserve__text {
  width: 60%;
  margin: 0;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1;
  color: #fff;
  text-align: left;
  transform: translateX(0.3125rem);
}

#limited-modal .content .setC-option {
  text-align: center;
  padding-bottom: 1.25rem;
  border-bottom: 0.5px solid #707070;
}

#limited-modal .content .setC-option__image {
  position: relative;
  display: flex;
  flex-direction: row;
}

#limited-modal .content .setC-option__image img {
  display: block;
  width: 23%;
  height: 2.8125rem;
}

#limited-modal .content .setC-option__image--04 {
  margin-left: 1.625rem;
}

#limited-modal .content .setC-option__image::after {
  content: "+";
  position: absolute;
  left: 0;
  font-size: 20px;
  font-size: 1.25rem;
  transform: translate(72px, 11px);
}

@media screen and (max-width: 374px) {
  #limited-modal .content .setC-option__image::after {
    transform: translate(69px, 11px);
  }
}

#limited-modal .content .setC-option__text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1;
}

#limited-modal .content .setC-option__text--title {
  margin: 0.96875rem 0;
}

#limited-modal .content .setC-option__text--price {
  margin: 0.96875rem 0;
}

#limited-modal .content .setC-option__text--tax {
  font-size: 10px;
  font-size: 0.625rem;
}

#limited-modal .content .setC-option__purchaseButton button {
  width: 100%;
  padding: 0.625rem 0;
  border: none;
  background-color: #51a3d1;
  border-radius: 0.125rem;
}

#limited-modal .content .setC-option__purchaseButton .reserve {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#limited-modal .content .setC-option__purchaseButton .reserve__image {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 20px;
}

#limited-modal .content .setC-option__purchaseButton .reserve__text {
  width: 60%;
  margin: 0;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1;
  color: #fff;
  text-align: left;
  transform: translateX(0.3125rem);
}

#limited-modal .content .text {
  margin: 0 auto;
  margin-top: 1.25rem;
  font-size: 12px;
  font-size: 0.75rem;
  color: #3e3a39;
  text-align: left;
  padding: 0rem 1.6875rem 2.5rem 1.6875rem;
}

@media screen and (max-width: 374px) {
  #limited-modal .content .text {
    padding: 0rem 0.875rem 1.25rem 0.875rem;
  }
}

#limited-modal .content .text__date {
  margin: 0;
  margin-top: 0.4375rem;
  line-height: 1;
}

#limited-modal .content .text__free {
  margin: 0;
  margin-top: 0.3125rem;
  line-height: 1;
}

@media screen and (max-width: 735px) {
  #limited-modal .sqs-layout .sqs-row .sqs-block:first-child {
    padding-top: 0 !important;
  }
  #limited-modal .sqs-layout .sqs-row .sqs-block:last-child {
    padding-bottom: 0 !important;
  }
  #limited-modal .sqs-layout .sqs-row .sqs-block {
    padding: 0;
  }
}
